/* eslint @typescript-eslint/no-empty-function: 0 */
import {imageUrl} from 'common/sanity';
import {breakpoint} from 'common/theme';
import {SanityImageSliderData} from 'common/types';
import {FC, useState} from 'react';
import Marquee from 'react-marquee-slider';
import styled, {DefaultTheme} from 'styled-components';
import Carousel, {Modal, ModalGateway} from 'react-images';
import NextImage from 'next/image';
// @ts-ignore
import colorAlpha from 'color-alpha';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.spacing(2)}px;
  margin-bottom: ${({theme}) => theme.spacing(2)}px;
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(3)}px;
    margin-bottom: ${({theme}) => theme.spacing(3)}px;
  `};
`;

const ImageItemWrapper = styled.div<{background: string; aspect: number}>`
  height: 120px;
  width: ${({aspect}) => `${120 * aspect}px`};
  display: block;
  background-color: ${({background}) => background};
  z-index: 1;
  ${breakpoint.size('sm')`
    height: 150px;
    width: ${({aspect}: {theme: DefaultTheme; aspect: number}) => `${150 * aspect}px`};
  `}
  ${breakpoint.size('md')`
    height: 170px;
    width: ${({aspect}: {theme: DefaultTheme; aspect: number}) => `${170 * aspect}px`};
  `};
  ${breakpoint.size('lg')`
    height: 200px;
    width: ${({aspect}: {theme: DefaultTheme; aspect: number}) => `${200 * aspect}px`};
  `};
`;

const ImageWrapper = styled.div<{offset: number}>`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0 ${({theme}) => theme.spacing(0.5)}px 0
    ${({offset, theme}) => (offset ? `${theme.spacing(1)}px` : `${theme.spacing(0.75)}px`)};
  ${breakpoint.size('sm')`
    margin: 0 ${({theme}) => theme.spacing(2)}px 0 ${({offset, theme}: {theme: DefaultTheme; offset: number}) =>
    offset ? `${theme.spacing(2)}px` : `${theme.spacing(1)}px`};
  `};
  ${breakpoint.size('md')`
    margin: 0 ${({theme}) => theme.spacing(4)}px 0 ${({offset, theme}: {theme: DefaultTheme; offset: number}) =>
    offset ? `${theme.spacing(2.5)}px` : `${theme.spacing(1.5)}px`};
  `};
  ${breakpoint.size('lg')`
    margin: 0 ${({theme}) => theme.spacing(7)}px 0 ${({offset, theme}: {theme: DefaultTheme; offset: number}) =>
    offset ? `${theme.spacing(3.5)}px` : `${theme.spacing(2)}px`};
    `}
  border-radius: 5px;
  overflow: hidden;
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 0.85;
  }
`;

const ImageFilter = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  margin-top: ${({theme}) => theme.spacing(0.5)}px;
  margin-bottom: ${({theme}) => theme.spacing(0.5)}px;
  ${breakpoint.size('sm')`
    margin-top: ${({theme}) => theme.spacing(1)}px;
    margin-bottom: ${({theme}) => theme.spacing(1)}px;
  `};
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(1.5)}px;
    margin-bottom: ${({theme}) => theme.spacing(1.5)}px;
  `};
`;

const Image: FC<SanityImageSliderData['images1'][0] & {offset?: boolean; onClick?: () => void}> = image => (
  <ImageWrapper offset={image.offset ? 1 : 0} onClick={() => image.onClick?.()}>
    <ImageItemWrapper background={colorAlpha(image.dominant.background, 0.4)} aspect={image.width / image.height}>
      <NextImage
        layout='fill'
        src={
          imageUrl(image)
            .width(600)
            .auto('format')
            .quality(80)
            .url() || ''
        }
        placeholder='blur'
        blurDataURL={image.lqip}
      />
    </ImageItemWrapper>
    <ImageFilter />
  </ImageWrapper>
);

const ImageSlider: FC<SanityImageSliderData> = props => {
  const [modal1Open, setModal1Open] = useState<boolean>(false);
  const [currentImage1, setCurrentImage1] = useState<number>(0);
  const openGallery1 = (index: number) => {
    setCurrentImage1(index);
    setModal1Open(true);
  };
  const toggleModal1 = () => setModal1Open(modal1Open => !modal1Open);
  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [currentImage2, setCurrentImage2] = useState<number>(0);
  const openGallery2 = (index: number) => {
    setCurrentImage2(index);
    setModal2Open(true);
  };
  const toggleModal2 = () => setModal2Open(modal2Open => !modal2Open);
  return (
    <Wrapper>
      <Content>
        <Marquee
          key='marquee-slider-1'
          velocity={12}
          direction='rtl'
          scatterRandomly={false}
          resetAfterTries={1}
          onFinish={() => {}}
          onInit={() => {}}>
          {props.images1.map((image, index) => (
            <Image key={index} {...image} onClick={() => openGallery1(index)} />
          ))}
        </Marquee>
      </Content>
      {props.images2 && props.images2.length > 0 && (
        <Content>
          <Marquee
            key='marquee-slider-2'
            velocity={22}
            direction='rtl'
            scatterRandomly={false}
            resetAfterTries={1}
            onFinish={() => {}}
            onInit={() => {}}>
            {props.images2.map((image, index) => (
              <Image offset key={index} {...image} onClick={() => openGallery2(index)} />
            ))}
          </Marquee>
        </Content>
      )}
      <ModalGateway>
        {modal1Open ? (
          <Modal onClose={toggleModal1}>
            <Carousel
              currentIndex={currentImage1}
              views={props.images1.map(item => ({
                source: {
                  download:
                    imageUrl(item)
                      .width(2500)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                  fullscreen:
                    imageUrl(item)
                      .width(1800)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                  regular:
                    imageUrl(item)
                      .width(1200)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                  thumbnail:
                    imageUrl(item)
                      .width(100)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                },
              }))}
            />
          </Modal>
        ) : null}
        {modal2Open ? (
          <Modal onClose={toggleModal2}>
            <Carousel
              currentIndex={currentImage2}
              views={props.images2.map(item => ({
                source: {
                  download:
                    imageUrl(item)
                      .width(2500)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                  fullscreen:
                    imageUrl(item)
                      .width(1800)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                  regular:
                    imageUrl(item)
                      .width(1200)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                  thumbnail:
                    imageUrl(item)
                      .width(100)
                      .auto('format')
                      .quality(80)
                      .url() || '',
                },
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Wrapper>
  );
};

export default ImageSlider;
